
<template>
  <div class="main">
    <div class="opt">
      <div class="title">{{$fanyi("订单进程")}}</div>
      <div class="pageJumpListContainer">
        <div class="pageJumpContainer">
          <div class="icon temporary">
            <img src="../../../../assets/user-img/PEDIDOSGUARDADOS.svg" alt="">
          </div>
          <div class="right" @click="$router.push({ name: 'userOrder',query:{status:'temporary'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.order_tamp' :endVal='newCountNumInfo.order_tamp' :duration='3000' /></div>
            <div class="text">{{$fanyi("临时保存的订单")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon pending"><img src="../../../../assets/user-img/PEDIDOSENCURSO.svg" alt=""></div>
          <div class="right" @click="$router.push({ name: 'userOrder',query:{status:'all'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.order_un_delivery' :endVal='newCountNumInfo.order_un_delivery' :duration='3000' /></div>
            <div class="text">{{$fanyi("所有待处理的订单")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon RECEPCIONPENDIENTE"><img src="../../../../assets/user-img/RECEPCIONPENDIENTE.svg" alt=""></div>
<!--          待收货订单-->
          <div class="right" @click="$router.push({ name: 'warehouse',query:{status:'ordersToBeReceived'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.order_purchase_uncomplete' :endVal='newCountNumInfo.order_purchase_uncomplete' :duration='3000' /></div>
            <div class="text">{{$fanyi("等待工厂发货的订单")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;">
          <div class="icon CONTROLDECALIDAD"><img src="../../../../assets/user-img/CONTROLDECALIDAD.svg" alt=""></div>
          <!--          检品中订单-->
          <div class="right" @click="$router.push({ name: 'warehouse',query:{status:'orderInInspection'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.order_inspecting' :endVal='newCountNumInfo.order_inspecting' :duration='3000' /></div>
            <div class="text">{{$fanyi("检品")}}</div>
          </div>
        </div>
      </div>
      <div class="pageJumpListContainer">
        <div class="pageJumpContainer" style="margin: 0 20px 20px 0;">
          <div class="icon WAREHOUSE"><img src="../../../../assets/user-img/WAREHOUSE.svg" alt=""></div>
          <!--          在RAKUMART仓库中订单-->
          <div class="right" @click="$router.push({ name: 'warehouse',query:{status:'orderInRAKUMARTWarehouse'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.order_up_complete' :endVal='newCountNumInfo.order_up_complete' :duration='3000' /></div>
            <div class="text">{{$fanyi("货物到达RAKUMART中国仓库")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon FLETEPENDIENTECOTIZACION"><img src="../../../../assets/user-img/FLETEPENDIENTECOTIZACION.svg" alt=""></div>
<!--          国际运费待报价配送单-->
          <div class="right" @click="$router.push({ name: 'warehouse',query:{status:'internationalFreightToBeQuotedDeliverySheet'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_wait_offer' :endVal='newCountNumInfo.porder_wait_offer' :duration='3000' /></div>
            <div class="text">{{$fanyi("国际运费报价")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon ENVIOPENDIENTEDEPAGO"><img src="../../../../assets/user-img/ENVIOPENDIENTEDEPAGO.svg" alt=""></div>
<!--          国际运费待支付配送单-->
          <div class="right" @click="$router.push({ name: 'warehouse',query:{status:'internationalFreightToBePaidShippingBill'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_wait_pay' :endVal='newCountNumInfo.porder_wait_pay' :duration='3000' /></div>
            <div class="text">{{$fanyi("国际运费待支付")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;">
          <div class="icon ESPERADERECOGIDA"><img src="../../../../assets/user-img/ESPERADERECOGIDA.svg" alt=""></div>
<!--          待发货配送单-->
          <div class="right" @click="$router.push({ name: 'wuliuStatus',query:{status:'orderForDelivery'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_pay_un_delivery' :endVal='newCountNumInfo.porder_pay_un_delivery' :duration='3000' /></div>
            <div class="text">{{$fanyi("待装仓")}}</div>
          </div>
        </div>
      </div>
      <div class="pageJumpListContainer">
        <div class="pageJumpContainer">
          <div class="icon ENTRANSITOHACIAESPAÑA"><img src="../../../../assets/user-img/ENTRANSITOHACIAESPAA.svg" alt=""></div>
<!--          运输中配送单-->
          <div class="right" @click="$router.push({ name: 'wuliuStatus',query:{status:'deliveryInTransit'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_in_sea' :endVal='newCountNumInfo.porder_in_sea' :duration='3000' /></div>
            <div class="text">{{$fanyi("中国发往西班牙的订单")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon ALMACENESPAÑA"><img src="../../../../assets/user-img/ALMACENESPAA.svg" alt=""></div>
<!--          到达RAKUMART运输中心配送单-->
          <div class="right" @click="$router.push({ name: 'wuliuStatus',query:{status:'deliveryFormToRAKUMARTTransportCentre'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_cleared' :endVal='newCountNumInfo.porder_cleared' :duration='3000' /></div>
            <div class="text">{{$fanyi("货物到达RAKUMART西班牙仓库")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer">
          <div class="icon ENVIONACIONALENCURSO"><img src="../../../../assets/user-img/ENVIONACIONALENCURSO.svg" alt=""></div>
<!--          西班牙派送配送单-->
          <div class="right" @click="$router.push({ name: 'wuliuStatus',query:{status:'spanishDeliveryOrder'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_foreign_express' :endVal='newCountNumInfo.porder_foreign_express' :duration='3000' /></div>
            <div class="text">{{$fanyi("西班牙派送中")}}</div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;">
          <div class="icon ENTREGADO"><img src="../../../../assets/user-img/ENTREGADO.svg" alt=""></div>
          <!--已签收配送单-->
          <div class="right" @click="$router.push({ name: 'wuliuStatus',query:{status:'internationalLogistics'}});update()">
            <div class="number"><countTo :startVal='countNumInfo.porder_receive' :endVal='newCountNumInfo.porder_receive' :duration='3000' /></div>
            <div class="text">{{$fanyi("已收货")}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: {
    countTo
  },
  data() {
    return {
      shopLoading:true,
      countNumInfo:{
        "order_tamp": 0, //临时订单
        "order_un_delivery": 0, //待处理订单(未完全发出)
        "order_purchase_uncomplete": 0, //购买中
        "order_up_complete": 0, //货物上架完成 (未完全发出)
        "order_inspecting": 0, //检品中
        "porder_wait_offer": 0, //配送单待报价
        "porder_wait_pay": 0, //配送单待支付
        "porder_pay_un_delivery": 0, //已支付待发货
        "porder_in_sea": 0, //国际运输中
        "porder_cleared": 0, //已通过
        "porder_foreign_express": 0, //外国运输中
        "porder_receive": 0 //已签收
      },
      newCountNumInfo:{
        "order_tamp": 0, //临时订单
        "order_un_delivery": 0, //待处理订单(未完全发出)
        "order_purchase_uncomplete": 0, //购买中
        "order_up_complete": 0, //货物上架完成 (未完全发出)
        "order_inspecting": 0, //检品中
        "porder_wait_offer": 0, //配送单待报价
        "porder_wait_pay": 0, //配送单待支付
        "porder_pay_un_delivery": 0, //已支付待发货
        "porder_in_sea": 0, //国际运输中
        "porder_cleared": 0, //已通过
        "porder_foreign_express": 0, //外国运输中
        "porder_receive": 0 //已签收
      }
    };
  },
  created() {
    this.getContentCountNum();
  },
  methods: {
    // 获取个人中心看板统计底部统计数据
    getContentCountNum(){
      this.$api.contentCountNum().then((res) => {
        this.newCountNumInfo = res.data;
      })
          .catch((err) => {
      });
    },
    update(){
      this.$emit('signStatusVerdict',1);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  .opt {
    width: 1052px;
    margin-bottom: 20px;
    .title{
      color: #2F2F2F;
      font-size: 18px;
      margin-bottom: 21px;
    }
    .pageJumpListContainer{
      display: flex;
      align-items: center;
      .pageJumpContainer{
        width: 248px;
        height: 124px;
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        border-radius: 6px;
        margin: 0 20px 20px 0;
        padding: 30px 0 23px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon{
          width: 64px;
          height: 64px;
          background: #DFE4FF;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .temporary{
          img{
            width: 17px;
            height: 23px;
          }
        }
        .pending{
          padding-right: 3px;
          img{
            width: 21px;
            height: 21px;
          }
        }
        .RECEPCIONPENDIENTE{
          img{
            width: 20px;
            height: 20px;
          }
        }
        .WAREHOUSE{
          img{
            width: 22px;
            height: 22px;
          }
        }
        .CONTROLDECALIDAD{
          img{
            width: 21px;
            height: 21px;
          }
        }
        .FLETEPENDIENTECOTIZACION{
          img{
            width: 18px;
            height: 22px;
          }
        }
        .ENVIOPENDIENTEDEPAGO{
          img{
            width: 23px;
            height: 23px;
          }
        }
        .ESPERADERECOGIDA{
          img{
            width: 21px;
            height: 21px;
          }
        }
        .ENTRANSITOHACIAESPAÑA{
          img{
            width: 24px;
            height: 24px;
          }
        }
        .ALMACENESPAÑA{
          img{
            width: 22px;
            height: 22px;
          }
        }
        .ENVIONACIONALENCURSO{
          img{
            width: 26px;
            height: 18px;
          }
        }
        .ENTREGADO{
          img{
            width: 22px;
            height: 22px;
          }
        }
        .right{
          .number{
            color: #2F2F2F;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 11px;
          }
          .text{
            color: #2F2F2F;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
